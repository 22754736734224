import * as React from "react";

export const Twitter = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 26 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M26 2.99098c-.9561.42095-1.9833.70537-3.0631.83393 1.1142-.66237 1.9477-1.70486 2.3451-2.93296-1.0467.61776-2.1924 1.05258-3.3871 1.28559-.8034-.85228-1.8675-1.41719-3.0272-1.60701-1.1596-.18983-2.3499.00605-3.3861.55721-1.0361.55117-1.8601 1.42678-2.344 2.49091-.4839 1.06412-.6007 2.25722-.3323 3.39405-2.121-.10581-4.19593-.65354-6.09011-1.60766-1.89418-.95411-3.56528-2.29328-4.90483-3.93059-.45803.785-.7214 1.69515-.7214 2.66446-.00051.8726.21577 1.73183.62965 2.50146.41389.76964 1.01257 1.42587 1.74294 1.91048-.84703-.02677-1.67538-.25417-2.4161-.66327v.06826c-.00008 1.22386.426 2.41006 1.20597 3.35736.77997.9472 1.86577 1.5972 3.07316 1.8396a5.40618 5.40618 0 0 1-2.40923.091c.34066 1.0531 1.00423 1.9739 1.89781 2.6337.89358.6597 1.97244 1.0253 3.08555 1.0456-1.88956 1.4738-4.22316 2.2732-6.62539 2.2697A10.9454 10.9454 0 0 1 0 19.1188c2.4384 1.5577 5.27688 2.3844 8.17581 2.3812 9.81329 0 15.17789-8.0753 15.17789-15.07889 0-.22754-.0057-.45735-.016-.68489 1.0435-.74977 1.9442-1.67821 2.66-2.74182L26 2.99098Z"
      fill="#fff"
    />
  </svg>
);
