import * as React from "react";

export const Link = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.76854 9.43183 2.16595 12.0344a5.69122 5.69122 0 0 0-1.66693 4.0243c0 1.5095.59962 2.957 1.66693 4.0244A5.69132 5.69132 0 0 0 6.19028 21.75a5.69127 5.69127 0 0 0 4.02432-1.6669l3.4676-3.4695a5.69045 5.69045 0 0 0 1.3991-2.2999 5.68961 5.68961 0 0 0 .1855-2.6857 5.69147 5.69147 0 0 0-1.0697-2.47046 5.6909 5.6909 0 0 0-2.0856-1.70231l-1.1116 1.1116a1.90125 1.90125 0 0 0-.2921.37749 3.79339 3.79339 0 0 1 1.671 1.00055 3.79441 3.79441 0 0 1 .9544 1.69783 3.7941 3.7941 0 0 1-.9921 3.6317l-3.46572 3.4675a3.79587 3.79587 0 0 1-2.68415 1.1118 3.79587 3.79587 0 0 1-2.68415-1.1118 3.7958 3.7958 0 0 1-1.11181-2.6841c0-1.0068.39993-1.9723 1.11181-2.6842l1.50426-1.5023a7.62232 7.62232 0 0 1-.2428-2.44137v.0019Z"
      fill="#fff"
    />
    <path
      d="M8.31787 5.88451a5.69077 5.69077 0 0 0-1.58462 4.98559 5.69114 5.69114 0 0 0 1.06974 2.4705 5.69167 5.69167 0 0 0 2.08554 1.7023l1.47007-1.472a3.79469 3.79469 0 0 1-1.70029-.9823 3.7936 3.7936 0 0 1-.98152-1.7007A3.79391 3.79391 0 0 1 9.659 7.22374l3.4657-3.46758a3.79597 3.79597 0 0 1 5.3683 5.3683l-1.5043 1.50234c.2125.7967.294 1.6219.2428 2.4414l2.6026-2.6026a5.69135 5.69135 0 0 0 1.6669-4.02434A5.69118 5.69118 0 0 0 15.8098.75c-1.5094 0-2.957.59961-4.0243 1.66693L8.31787 5.88451Z"
      fill="#fff"
    />
  </svg>
);
