import * as React from "react";

export const Facebook = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.5 11.0586c0 5.2203 3.79138 9.5611 8.75 10.4414v-7.5836H6.625V11H9.25V8.66638c0-2.625 1.6914-4.08276 4.0836-4.08276.7578 0 1.575.11638 2.3328.23276V7.5H14.325c-1.2836 0-1.575.64137-1.575 1.45862V11h2.8l-.4664 2.9164H12.75V21.5c4.9586-.8803 8.75-5.2202 8.75-10.4414C21.5 5.25125 16.775.5 11 .5S.5 5.25125.5 11.0586Z"
      fill="#fff"
    />
  </svg>
);
