import "../custom-types/assets.d";
import * as React from "react";
import { SyntheticEvent, useCallback, useState } from "react";
import Helmet from "react-helmet";
import favicon from "../assets/images/favicon.png";
import { ContentProvider } from "../technical/contentful/ContentProvider";
import { useContent } from "../technical/contentful/content";
import { Footer } from "../section/Footer";
import "../technical/firestore";
import { ExternalProvider } from "../technical/external-provider/ContentProvider";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import { TextKey } from "../technical/contentful/text";
import { useExternal } from "../technical/external-provider/content";
import { Header } from "../components/Header";
import styled from "styled-components";
import { Button } from "../components/Button";
import { Gauge, getNextGoal } from "../section/Gauge";
import { PRIMARY } from "../constant/Colors";
import * as moment from "moment";
import "moment/locale/fr";
import { Facebook } from "../assets/images/Facebook";
import { Link as LinkIcon } from "../assets/images/Link";
import { Whatsapp } from "../assets/images/Whatsapp";
import { Twitter } from "../assets/images/Twitter";
import { Sharers as BaseSharers } from "../section/Sharers";
import * as copy from "copy-to-clipboard";
// @ts-ignore
import md5 from "blueimp-md5";
import { CheckEmail } from "../section/CheckEmail";
import { TABLET } from "../constant/Breakpoints";
import { Skeleton } from "../components/Skeleton";
import { Instagram } from "../assets/images/Instagram";

moment.locale("fr");

const SHARE = {
  FACEBOOK: {
    icon: <Facebook width={21} />,
    text: TextKey.SHARE_CTA_FACEBOOK,
  },
  LINK: {
    icon: <LinkIcon width={21} />,
    text: TextKey.SHARE_CTA_LINK,
  },
  WHATSAPP: {
    icon: <Whatsapp width={21} />,
    text: TextKey.SHARE_CTA_WHATSAPP,
  },
  MESSENGER: {
    icon: <Instagram width={21} />,
    text: TextKey.SHARE_CTA_MESSENGER,
  },
  TWITTER: {
    icon: <Twitter width={21} />,
    text: TextKey.SHARE_CTA_TWITTER,
  },
};

const Container = styled.div`
  display: flex;
  max-width: 1280px;
  box-sizing: content-box;
  margin: 0 auto;
  padding: 48px 96px;

  @media (max-width: ${TABLET}px) {
    padding: 32px 24px;
    flex-direction: column;
  }

  > * {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 20px;
    padding-bottom: 20px;

    :not(:first-child) {
      padding-left: 20px;
    }

    :not(:last-child) {
      padding-right: 20px;
    }
  }
`;

const Line = styled.div`
  display: flex;
  margin-bottom: 22px;

  a {
    display: flex;
    flex: 1;
  }
`;

const Count = styled.div`
  display: flex;
  width: 120px;
  align-items: center;
  justify-content: center;
  background: #dedfff;
  color: ${PRIMARY};
  font-size: 18px;
  font-weight: 600;
`;

const FormTitle = styled.h2`
  font-size: 32px;
  line-height: 32px;
  font-weight: 600;
  color: ${PRIMARY};
  margin-bottom: 8px;
`;

const Sharers = styled(BaseSharers)`
  flex: 1;
`;

const Partage = () => {
  const urlSearchParams = new URLSearchParams(global.window?.location.search);
  const [firstname] = useState(urlSearchParams.get("firstname"));
  const [referral] = useState(md5(urlSearchParams.get("email")));
  const { texts, seo } = useContent();
  const { ready, signersCount, share } = useExternal();
  const [shared, setShared] = useState(false);
  const [copied, setCopied] = useState(false);

  const incShare = useCallback(
    async (method: string) => {
      setShared(true);

      await fetch(
        "https://europe-west1-papillon-ou-zemmour.cloudfunctions.net/shareInc",
        {
          method: "POST",
          body: JSON.stringify({
            data: {
              platform: method,
              firstname: shared ? undefined : firstname,
            },
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    [shared, setShared, firstname]
  );

  return (
    <>
      <Helmet
        title={seo.title}
        link={[{ rel: "icon", href: favicon }]}
        htmlAttributes={{
          lang: "fr",
        }}
        meta={[
          {
            name: "description",
            content: seo.description,
          },
          {
            name: "viewport",
            content: "width=device-width, initial-scale=0.7",
          },
          { property: "og:url", content: "https://zemmourcestnon.fr/" },
          { property: "og:type", content: "website" },
          { property: "og:title", content: seo.title },
          {
            property: "og:description",
            content: seo.description,
          },
          {
            property: "og:image",
            content: `https:${seo.image}`,
          },
          { property: "og:locale", content: "FR" },
          { property: "twitter:card", content: "summary_large_image" },
        ]}
      />
      <Header
        maxWidth={600}
        count={signersCount}
        title={documentToPlainTextString(
          texts[TextKey.SHARE_HEADER_BEFORE_LOGO].document
        )}
        subtitle={documentToPlainTextString(
          texts[TextKey.SHARE_HEADER_AFTER_LOGO].document
        )}
      />
      <Container className="flex-col md:flex-row">
        <div>
          {Object.keys(SHARE).map(platform => {
            const { text: textKey, icon } = SHARE[platform];
            const { document, href: rawHref } = texts[textKey];
            const href = rawHref
              ?.replace("{{ref}}", referral)
              .replace(encodeURIComponent("{{ref}}"), referral);

            const onClick =
              platform === "LINK"
                ? (e: SyntheticEvent) => {
                    e.preventDefault();
                    copy(href);
                    setCopied(true);
                    setTimeout(() => {
                      setCopied(false);
                    }, 3000);
                    incShare(platform);
                  }
                : () => incShare(platform);

            return (
              <Line key={platform}>
                <a href={href} target="_blank" onClick={onClick}>
                  <Button className="md:text-xl text-base" icon={icon}>
                    {platform === "LINK" && copied
                      ? "Lien copié !"
                      : documentToPlainTextString(document)}
                  </Button>
                  <Count>{share[platform]}</Count>
                </a>
              </Line>
            );
          })}
        </div>
        <div>
          {ready ? (
            <>
              <FormTitle>
                {documentToPlainTextString(
                  texts[TextKey.SHARE_FORM_TITLE].document
                )
                  .replace("{{count}}", String(signersCount))
                  .replace("{{goal}}", String(getNextGoal(signersCount)))}
              </FormTitle>
              <Gauge />
              <Sharers limit={50} />
            </>
          ) : (
            <Skeleton lines={12} />
          )}
        </div>
      </Container>
      <Footer />
      <CheckEmail />
    </>
  );
};

export default () => (
  <ContentProvider>
    <ExternalProvider>
      <Partage />
    </ExternalProvider>
  </ContentProvider>
);
