import * as React from "react";

export const Whatsapp = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2889 12.5843c-.2608-.1304-1.5391-.7587-1.7771-.8462-.238-.0866-.4113-.1295-.5854.1313-.1733.259-.6711.8452-.8225 1.0185-.1523.1741-.3036.1951-.5635.0656-.2599-.1312-1.0981-.4051-2.09126-1.2906-.77262-.6895-1.295-1.5409-1.44637-1.80163-.15138-.25987-.01663-.40075.11375-.53025.11725-.11637.25987-.30362.39025-.455.13037-.15225.17325-.26075.25987-.43487.0875-.17325.04375-.32463-.02187-.455-.06475-.13038-.5845-1.4105-.8015-1.93112-.21088-.50663-.42525-.4375-.5845-.44625-.15225-.007-.3255-.00875-.49875-.00875-.17325 0-.455.06475-.693.3255-.23887.25987-.91.889-.91 2.16912 0 1.27925.931 2.51565 1.06138 2.68975.13037.1732 1.83312 2.8 4.4415 3.9261.6212.2678 1.1051.4279 1.4822.5469.623.1986 1.19.1706 1.638.1032.4988-.0743 1.5383-.6291 1.7553-1.2363.2161-.6073.2161-1.1279.1514-1.2364-.0648-.1085-.238-.1733-.4988-.3036h.0009Zm-4.7443 6.4776h-.0035a8.6357 8.6357 0 0 1-4.40295-1.2058l-.315-.1872-3.27425.8592.87413-3.192-.20563-.3272a8.6283 8.6283 0 0 1-1.32212-4.6025c.00175-4.76875 3.88149-8.64849 8.65282-8.64849 2.31 0 4.4818.90125 6.1145 2.53574a8.59506 8.59506 0 0 1 1.878 2.80603 8.59458 8.59458 0 0 1 .6525 3.31282c-.0017 4.7688-3.8815 8.6494-8.6485 8.6494Zm7.3605-16.00987a10.33755 10.33755 0 0 0-3.3748-2.2638 10.33773 10.33773 0 0 0-3.9865-.7882C4.80902.00004.14003 4.66816.13828 10.4055c-.00087 1.834.47775 3.6243 1.38862 5.2019L.05078 21l5.51599-1.4472a10.39726 10.39726 0 0 0 4.97353 1.267h.0043c5.7348 0 10.4038-4.6682 10.4055-10.4064a10.34146 10.34146 0 0 0-.7854-3.9856 10.34146 10.34146 0 0 0-2.2596-3.37577Z"
      fill="#fff"
    />
  </svg>
);
