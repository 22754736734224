import * as React from "react";
import { useCallback, useRef } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { TABLET } from "../constant/Breakpoints";
import { Button } from "../components/Button";
import { PRIMARY } from "../constant/Colors";
import { useElementSize, useLocalStorage, useSsr } from "usehooks-ts";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import { useContent } from "../technical/contentful/content";
import { TextKey } from "../technical/contentful/text";

const PaddingBody = createGlobalStyle<{ padding: number }>`
  body {
    padding-bottom: ${({ padding }) => padding}px;
  }
`;

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #f5f5ff;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1280px;
  box-sizing: content-box;
  margin: 0 auto;
  padding: 48px 96px;

  @media (max-width: ${TABLET}px) {
    padding: 32px 24px;
    flex-direction: column;
  }

  > p {
    font-size: 20px;
    line-height: 23px;
    font-weight: 600;
    margin-right: 58px;

    @media (max-width: ${TABLET}px) {
      margin-bottom: 16px;
    }
  }
`;

const CTA = styled(Button)`
  background: ${PRIMARY};
  min-width: 322px;
  text-transform: none;
  font-size: 18px;
  height: 58px;

  @media (max-width: ${TABLET}px) {
    width: 100%;
  }
`;

export const CheckEmail = () => {
  const { texts } = useContent();
  const [seen, setSeen] = useLocalStorage("check-email-seen", false);
  const { isServer } = useSsr();
  const handleClick = useCallback(() => {
    setSeen(true);
  }, [setSeen]);
  const containerRef = useRef<HTMLDivElement>(null);
  const { height: padding } = useElementSize(containerRef);

  if (seen || isServer) {
    return null;
  }

  return (
    <Container>
      <PaddingBody padding={padding} />
      <Content ref={containerRef}>
        <p>
          {documentToPlainTextString(texts[TextKey.CHECK_EMAIL_TEXT].document)}
        </p>
        <CTA onClick={handleClick}>
          {documentToPlainTextString(texts[TextKey.CHECK_EMAIL_CTA].document)}
        </CTA>
      </Content>
    </Container>
  );
};
